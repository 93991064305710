.button {
    width: 270px;
    height: 74px;
    border-radius: 10px;
    outline: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 21px;
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent;

    &__border {
        background: transparent;

        &_yellow {
            color: $white;
            border-color: $yellow;

            &:hover {
                background: $yellow;
                color: $black;
            }

            &[disabled] {
                &, &:hover {
                    color: $darkGrey;
                    border-color: $darkGrey;
                    background: transparent;
                }
            }
        }

        &_black {
            color: $black;
            border-color: $black;

            &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    &__yellow {
        background: $yellow;
        border-color: $yellow;
        color: $darkGrey;

        &:hover {
            color: $yellow;
            background: $darkGrey;
        }
    }

    &__black {
        background: $black;
        border-color: $yellow;
        color: $yellow;

        &:hover {
            color: $white;
            background: $black;
            border-color: $black;
        }
    }

    &__center {
        margin: 0 auto;
    }

    &__form {
        margin-top: 66px;
    }
}

.link {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: $gray;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: $white;
    }

    &__active {
        color: $white;
        pointer-events: none;
    }
}

.title {
    &-biggest {
        font-weight: 800;
        font-size: 7.2rem;
        line-height: 1.18;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }

    &-section {
        font-weight: 800;
        font-size: 4.5rem;
        line-height: 1.5;
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }
}


.text {
    &__white {
        color: $white;
    }

    &__gray {
        color: $gray;
    }

    &__black {
        color: $black;
    }

    &__yellow {
        color: $yellow;
    }

    &__center {
        text-align: center;
    }
}

.text__content {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.44;
    letter-spacing: 0.02em;
}

.section {
    &-dark {
        background: $dark;
    }

    &-dark2 {
        background: $dark2;
    }

    &-yellow {
        background: $yellow;
    }

    &-gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.528) 16.93%, rgba(0, 0, 0, 0.8) 100%);
    }
}

.gradient-mask {
    position: absolute;
    pointer-events: none;
    z-index: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.528) 16.93%, rgba(0, 0, 0, 0.8) 100%);
    width: 100%;
    height: 100%;
    top: 0;
}

.row__button {
    width: 100%;
    padding: 5rem 2rem;
    border: none;
    border-top: 1px solid $yellow;
    border-bottom: 1px solid $yellow;
    background: $black;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 5;

    &_text {
        font-weight: 500;
        font-size: 5.4rem;
        line-height: 1;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $yellow;
        text-align: center;
        transition: all 0.3s ease-in-out;
        margin: 0 10rem;
    }

    &:hover {
        background: $yellow;
        //border-color: $black;

        .row__button_text {
            color: $black;
        }

        .triangle__icon {
            stroke: $black !important;
        }

        .triangle__icon {
            animation: trianglesHover 1.2s ease-in-out infinite forwards;
        }

        .triangle__icon_middle {
            animation: trianglesHover 1.2s ease-in-out infinite 0.4s forwards;
        }

        .triangle__icon_fill {
            animation: trianglesHover 1.2s ease-in-out infinite 0.8s forwards;
        }
    }
}

.triangles {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 5.4rem;

}

.triangle__icon {
    animation: triangles 1.2s ease-in-out infinite forwards;
}

.triangle__icon_middle {
    animation: triangles 1.2s ease-in-out infinite 0.4s forwards;
}

.triangle__icon_fill {
    animation: triangles 1.2s ease-in-out infinite 0.8s forwards;
}

@keyframes triangles {
    0% {
        fill: rgba($yellow, 1)
    }
    50% {
        fill: rgba($yellow, 0.5)
    }
    100% {
        fill: rgba($yellow, 0)
    }

}

@keyframes trianglesHover {
    0% {
        fill: rgba($black, 1)
    }
    50% {
        fill: rgba($black, 0.5)
    }
    100% {
        fill: rgba($black, 0)
    }

}

.triangle {
    width: 4.6rem;
    height: 5.4rem;
    display: flex;

    &__icon {
        fill: rgba($yellow, 0);
        stroke: #ffe500;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    &__left {
        transform: rotate(-180deg);
    }
}

section {
    position: relative;
}

.icon {
    width: 100%;
    height: 100%;
}

@mixin customScrollbar($size, $thumbcolor, $bg) {
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
        background: $bg;
        border-radius: 12px;
    }

    &::-webkit-scrollbar-track {
        background: $bg;
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: $thumbcolor;
    }
}


.animation {
    position: absolute;
    pointer-events: none;
}


.lines {
    &__main {
        &_left {
            left: 0;
            top: -5%;
            position: absolute;
            width: 90.9em;
            height: 129.9em;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &_right {
            right: 0;
            top: 0;
            left: auto;
            position: absolute;
            width: 90.9em;
            height: 129.9em;
        }
    }

    &__for-partners {
        right: 0;
        top: -5%;
        width: 179em;
        height: 96.3em;
    }

    &__advantages {
        bottom: 0;
        left: 0;
        width: 179em;
        height: 160em;
    }

    &__for-advertisers {
        right: 0;
        top: 0;
        width: 179em;
        height: 101.8em;
    }

    &__testimonials_left {
        left: 0;
        bottom: 0;
        width: 81.2em;
        height: 133.7em;
    }

    &__testimonials_right {
        right: 0;
        bottom: 0;
        width: 73.1em;
        height: 133.7em;
    }

    &__faq {
        right: 0;
        bottom: -2%;
        z-index: 2;
    }

    &__contacts {
        left: 0;
        top: 0;
        width: 179em;
        height: 111.3em;
    }
}


.lines__main {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: auto;
    pointer-events: none;
    user-select: none;
}

.lines__right {
    position: absolute;
    right: 0;
    bottom: 13rem;
    height: auto;
    width: 96.4rem;
}


.lines {
    &__group {
        display: grid;
        grid-template-rows: repeat(3, auto);
        grid-template-columns: 1fr;
        grid-gap: 2.6em;
        width: max-content;
        position: absolute;

        &_black {
            .lines__item {
                background: #000;
            }
        }

        &_yellow {
            .lines__item {
                background: $yellow;
            }
        }
    }

    &__item {
        width: 16em;
        height: 2.5em;

        &_left {
            &:first-child {
                animation: linesLeft 3s linear infinite 1s;
            }

            &:nth-child(2) {
                animation: linesLeft 3s linear infinite 2s;
            }

            &:nth-child(3) {
                animation: linesLeft 3s linear infinite;
            }
        }

        &_right {
            &:first-child {
                animation: linesRight 3s linear infinite 1s;
            }

            &:nth-child(2) {
                animation: linesRight 3s linear infinite 2s;
            }

            &:nth-child(3) {
                animation: linesRight 3s linear infinite;
            }
        }
    }

}

@keyframes linesLeft {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10%);
    }
    50% {
        transform: translateX(-20%);
    }
    75% {
        transform: translateX(-10%);
    }
    100% {
        transform: translateX(0%);
    }

}

@keyframes linesRight {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(10%);
    }
    50% {
        transform: translateX(20%);
    }
    75% {
        transform: translateX(10%);
    }
    100% {
        transform: translateX(0%);
    }

}

.title__mask {
    display: block;
    overflow: hidden;

    &_text {
        display: block;
    }

}


canvas {
    display: block;
    vertical-align: bottom;
}

/* ---- particles.js container ---- */
.particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}


.particles-js-page {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
}

.dots-big {
    width: 90.6rem;
    height: 29.6rem;
    position: absolute;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &__icon {
        width: 100%;
        height: 100%;
    }
}

.crosses {
    width: 102px;
    height: 23px;
    position: absolute;
    pointer-events: none;

    svg {

        fill: $yellow;
    }

    &__black {
        svg {
            fill: $black;
        }
    }
}

.dots-small {
    width: 1.7em;
    height: 13.4em;
    position: absolute;
    pointer-events: none;


    &_rotate {
        transform: rotate(45deg);
    }
}

.squares {
    width: 26.3rem;
    height: 10.3rem;
    position: absolute;
    pointer-events: none;


}

.lines-group {
    width: 33.6rem;
    height: 10rem;
    position: absolute;
    pointer-events: none;
    transform: rotate(180deg);
}

.lines-group_black {
    position: absolute;
    pointer-events: none;
    width: 17.1rem;
}

.arrows__icon {
    position: absolute;
    width: 39.1rem;
    height: 4.1rem;
    fill: #877902;

    &_left {
        transform: rotate(-180deg);
    }
}

.dots-double {
    position: absolute;
    width: 6px;
    height: 23px;
    z-index: 5;
    transition: all 0.3s ease-in-out;

    svg {
        fill: $dark;
    }

    &_yellow {
        transform: rotate(90deg);

        svg {
            fill: $yellow;
        }
    }


}

.dots-group {
    width: 160px;
    height: 22px;
    position: absolute;
    z-index: 5;
    pointer-events: none;

    svg {

        fill: $dark;
    }

    &__yellow {
        svg {
            fill: $yellow;
        }

    }
}

.text__element {
    position: absolute;
    right: -7%;
    top: 14rem;
    z-index: 2;
    width: 3.1rem;

    &_name {
        height: 64rem;

        svg {
            fill: #111217;
        }
    }

    &_grey {
        svg {
            fill: $darkGrey;
        }
    }

    &_left {
        position: absolute;
        transform: rotate(-180deg);
        right: auto;
        left: -7%;
        top: 106rem;

    }
}

.cf-turnstile {
    iframe {
        display: block;
        margin: 0 auto;
    }

    .iframe-wrapper {
        position: absolute;
        pointer-events: none;
        width: 300px;
        height: 65px;
        left: calc(50% - 150px);
        border: 1px solid #111217;
        border-radius: 10px;
        outline: 5px solid #111217;
        display: none;
    }
}

@keyframes loader {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    width: 30px;
    margin-right: 5px;
    display: block;
    animation: loader 1.5s ease-in-out infinite;
}
