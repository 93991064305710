//@media (max-width: 1760px) {
//  .row__button_text {
//    margin: 0 8rem;
//    font-size: 5.6rem;
//  }
//}

@media (max-width: 1650px) {
    .socials__links {
        right: -70px;
    }
    .contact-us__arrows {
        right: -5%;
    }
}

@media (max-width: 1600px) {
    html {
        font-size: 9.5px;
    }
    .text__element {
        right: -5%;
    }
    .text__element_left {
        left: -5%;
    }
    .cooperation__arrows_right {
        right: -5%;
    }
    .cooperation__arrows_left {
        left: -5%;
    }
}

@media (max-width: 1580px) and (orientation: landscape) {
    .container__404 {
        .lines {
            &__top {
                top: -10%;
            }

            &__bottom {
                bottom: -10%;
            }
        }
    }
}

@media (max-width: 1460px) {
    .about-us_block {
        padding: 4rem 3rem;
    }
    .socials__links {
        right: -12px;
    }
}

@media (max-width: 1400px) {
    html {
        font-size: 9px;
    }
    .container {
        max-width: 1140px;
    }
    .menu__list {
        grid-gap: 24px;
    }
    .header__wrapper .menu {
        margin-right: 6rem;
    }
    .header__button {
        margin: 0 20px;
    }
    .description__block {
        padding: 24px 20px;
    }
    .text__content {
        font-size: 24px;
    }
    .faq__item_title {
        font-size: 20px;
    }
    .faq__item_content {
        font-size: 18px;
    }
    .faq__item {
        height: 140px;

        &_active {
            height: 300px;
        }
    }
    .faq__item_content {
        max-height: 130px;
    }
    .faq__item_question {
        margin-bottom: 24px;
    }
    .faq__item_last:after {
        top: 139px;
    }
    .flip-card {
        height: 375px;
    }
}

@media (max-width: 1280px) {
    html {
        font-size: 8.5px;
    }
    .container {
        max-width: 1024px;
    }
    .link {
        font-size: 16px;
    }
    .header__button {
        font-size: 16px;
        padding: 15px 20px;
        margin: 0 20px;
    }
    .main {
        .socials__links {
            right: -12px;
        }
    }

    .text__element {
        right: -30px;
    }
    .faq__item_content {
        font-size: 16px;
    }
    .header__container {
        padding: 22px 0;
    }
    .faq__item {
        height: 120px;

        &_active {
            height: 255px;
        }
    }
    .faq__item_content {
        max-height: 120px;
    }
    .faq__item_title {
        font-size: 18px;

    }
    .faq__item_question {
        margin-bottom: 20px;
    }
    .faq__item_container {
        padding: 24px 64px 40px;
    }
    .faq__item_last:after {
        top: 118px;
        width: 96.3%;
    }
    .flip-card {
        height: 363px;
    }
    .animation {
        font-size: 90%;
    }
    .about-us_text {
        font-size: 2rem;
        min-height: 11rem;
    }
    .testimonials__lines_top {
        top: 4%;
    }
    .lines__group {
        font-size: 90%;

    }
    .testimonials__lines_bottom {
        bottom: 3%;
    }
    .for-partners__crosses_left {
        transform: rotate(0);
        top: -35%;
        left: 20px;
    }
    .for-advertisers__crosses {
        transform: translateX(0);
        left: 20px;
        top: -35%;
    }
    .testimonials__dots_bottom {
        left: 2%;
    }
    .testimonials__dots_top {
        right: 2%;
    }
    .header__wrapper .menu {
        margin-right: 4rem;
    }
    .menu__list {
        grid-gap: 20px;
    }
    .faq__item_title {
        margin-right: 20px;
    }
    .container__404 {
        grid-template-columns: 52% 48%;

        .lines {
            &__top {
                top: 0;
            }

            &__bottom {
                bottom: 0;
            }
        }
    }
}

@media (max-width: 1100px) {
    html {
        font-size: 8px;
    }
    .container {
        max-width: 940px;
    }
    .link {
        font-size: 14px;

    }

    .description__text {
        font-size: 13px;
    }
    .button {
        width: 230px;
        height: 64px;
        font-size: 18px;

    }
    .header__button {
        font-size: 14px;
        padding: 10px 20px;
        height: auto;
        width: max-content;
    }
    .text__content {
        font-size: 20px;
    }
    .row__button_text {
        margin: 0 5rem;
    }
    .testimonial__text {
        font-size: 13px;
    }
    .testimonial__block {
        padding: 4rem 3rem;
    }
    .testimonial__title {
        margin-bottom: 20px;
    }
    .header__wrapper .menu {
        margin-right: 4rem;
    }
    .about-us_dots_group {
        top: 5%;
        right: 13%;
        grid-gap: 0.2rem;
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        right: 17%;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        left: 17%;
    }
    .faq__item_last:after {
        width: 95.8%;
        top: 119px;
    }
    .flip-card {
        height: 330px;
    }
    .about-us_block {
        padding: 3.6rem 2rem;
    }
}

@media (max-width: 1025px) {
    .notification__text {
        max-width: 85%;
    }
    .about-us__card {
        width: auto;
        min-height: auto;
    }
    .for-advertisers__button {
        margin-bottom: 23rem;
    }
    .for-advertisers__section {
        padding: 24rem 0 14rem;
    }
    .about-us__wrapper {
        display: block;
    }
    .about-us_block {
        height: 100%;
        justify-content: flex-start;
        min-height: auto;
    }
    .about-us__card {
        height: auto !important;
    }
    .about-us_item {
        height: 100%;
    }
    .swiper-slide-active {
        .about-us_num {
            color: #ffe500 !important;
        }

    }
    .about-us__container {
        overflow: inherit;
    }
    .about-us__scroll {
        display: none;
    }

    .container__404 {
        grid-template-columns: 50% 50%;

        .container__404__right {
            padding: 40px 20px;
        }
        .logo__404 {
            max-width: 260px;
        }
    }
}

@media (max-width: 1025px) and (orientation: portrait) {
    .container__404 {
        .lines {
            width: 150%;
            max-width: none;
        }
    }
}

@media (max-width: 1000px) {
    html {
        font-size: 7.5px;
    }
    .container {
        max-width: 840px;
    }
    .menu__list {
        grid-gap: 16px;
    }
    .header__button {
        margin: 0 16px;
        font-size: 13px;
    }
    .link {
        font-size: 13px;
    }
    .languages__line {
        margin: 0 6px;
    }
    .text__content {
        font-size: 22px;
    }
    .main {
        .socials__links {
            right: 5px;
        }
    }

    .faq__item {
        height: 110px;

        &_active {
            height: 220px;
        }
    }
    .faq__item_content {
        max-height: 100px;
    }
    .faq__item_content {
        font-size: 14px;
    }
    .faq__item_last:after {
        top: 108px;
        height: 0.5px;
        opacity: 0.5;
    }
    .flip-card {
        height: 310px;
    }

    .testimonials__lines-group {
        top: 5%;
    }
}

@media (max-width: 825px) {
    html {
        font-size: 7px;
    }
    .container {
        max-width: 760px;
    }
    .row__button_text {
        margin: 0 3rem;
    }
    .description__text {
        font-size: 11px;
    }
    .link {
        font-size: 12px;
    }
    .menu__list {
        grid-gap: 12px;
    }
    .header__button {
        margin: 0 12px;
        font-size: 12px;
    }
    .title-biggest {
        font-size: 6.4rem;
    }
    .title-section {
        font-size: 4.8rem;
    }
    .text__element {
        display: none;
    }
    .step__block {
        padding: 8rem 2rem;
    }
    .faq__item_container {
        padding: 20px 60px 32px;
    }
    .faq__item {
        height: 105px;

        &_active {
            height: 200px;
        }
    }
    .faq__item_question {
        margin-bottom: 16px;
    }
    .faq__item_content {
        max-height: 84px;
    }
    .faq__item_content {
        font-size: 14px;
    }
    .faq__item_last:after {
        top: 104px;
        width: 95.6%;
        height: 0.5px;
    }
    .header__wrapper .menu {
        margin-right: 2rem;
    }

    .for-partners__crosses_bottom {
        left: 50%;
        transform: translateX(-50%);
        bottom: -30%;
    }
    .faq__dots-group {
        display: none;
    }
    .flip-card {
        height: 300px;
    }
    .lines__testimonials {
        font-size: 75%;
    }
    .testimonials__slider_container {
        padding: 0 !important;
        max-width: 100% !important;
    }
    .testimonials__slider {
        .swiper-slide {
            transform: scale(1) !important;
            opacity: 1 !important;
        }

        .swiper-slide-active {
            transform: scale(1) !important;
        }
    }
    .testimonial__block {
        padding: 40px 10px;
    }
    .testimonial__title {
        font-size: 18px;
    }
    .testimonial__text {
        font-size: 15px;
    }
    .testimonials__slider {
        margin-bottom: 8rem;
    }
    .testimonials__title {
        margin-bottom: 2rem;
    }
    .swiper-navigations {
        position: absolute;
        bottom: -11rem;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        justify-content: space-between;
        height: 54px;
        align-items: center;

        .swiper-button-next, .swiper-rtl .swiper-button-prev, .swiper-button-prev, .swiper-rtl .swiper-button-next, .swiper-pagination {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            margin-top: 0;

        }

        .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
            transform: translate(0, 0);
            margin: 0 2rem;
        }
    }
    .step__block_title br {
        display: block;
    }
}

@media (max-width: 820px) {
    .container__404 {
        grid-template-columns: 45% 55%;

        .lines {
            width: 180%;
            max-width: none;
        }
    }
}

@media (max-width: 750px) {
    html {
        font-size: 6.5px;
    }
    .logo {
        max-width: 145px;
        height: 54px;
    }
    .header__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 100%;
        //background: linear-gradient(180deg, rgba(0, 0, 0, 0) -18.64%, rgba(0, 0, 0, 0.8) 100%);
        background: $black;
        width: 100%;
        left: 0;
        transition: all 0.5s ease-in-out;
        transform: translateX(-105%);

        border-top: 1px solid rgba(0, 0, 0, 0.1);
        min-height: calc(100vh - 98px);
        height: 100%;
    }
    .header__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        width: 100%;
        justify-content: center;
        padding: 118px 54px;
    }
    .header {
        &__mask {
            position: absolute;
            width: 100%;
            height: calc(100% + 100px);
            left: 0;
            top: -100px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) -18.64%, rgba(0, 0, 0, 0.8) 100%);
            z-index: 2;
            pointer-events: none;
        }

        .menu__list {
            grid-template-columns: 1fr;
            grid-gap: 32px;
            margin-bottom: 52px;

            .link {
                font-size: 24px;
                text-transform: uppercase;
                font-weight: 500;
                color: $gray;

                &:hover {
                    font-weight: 700;
                    color: $white;
                }
            }

        }

        .menu {
            width: 100%;
            position: relative;
            z-index: 3;
            max-width: 320px;
            margin: 0 auto;
        }

        .header__buttons {
            flex-direction: column;
            position: relative;
            z-index: 3;
            width: 100%;
        }

        .lang {
            font-size: 18px;
        }

        .languages__line {
            background: $gray;
            margin: 0 24px;
        }

        .header__button {
            margin: 20px auto 32px;
            padding: 10px 36px;
            width: 320px;
            max-width: 100%;
            height: 72px;
            color: $white;
            border-color: $yellow;
            font-size: 18px;
        }

        &__lines {
            &_menu {
                display: block;
                right: 0;
                bottom: 0;
                width: 100%;
                pointer-events: none;
                position: absolute;

                img {
                    width: 100%;
                }
            }
        }
    }
    .sing-in__link {
        padding: 10px 36px;
        min-width: 320px;
        height: 74px;
        color: $white !important;
        border: 2px solid $yellow;
        border-radius: 10px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu__btn {
        display: grid;
    }
    .descriptions {
        grid-template-columns: 1fr 1fr;
    }
    .description__text {
        font-size: 15px;
    }

    .about-us_num {
        font-size: 54px;
    }
    .about-us_text {
        font-size: 18px;
        min-height: auto;
    }
    .about-us_block {
        padding: 30px 14px;
    }
    .about-us_item {
        min-width: auto;
        height: auto;
    }
    .swiper-slide-active {
        .about-us_num {
            color: #ffe500 !important;
        }

    }
    .about-us__container {
        overflow: inherit;
    }
    .main {
        .socials__links {
            right: 10px;
        }
    }

    .testimonials__lines-group {
        top: 7rem;
    }
    .row__button_text {
        font-size: 5rem;
    }

    .menu__mob {
        background: $black;

        .header__lines {
            opacity: 1;
        }

        .header__content {
            transform: translateX(0);
        }
    }
    .about-us_dots_group {
        top: auto;
        right: 20%;
        grid-gap: 0.5rem;
    }
    .testimonials__lines_big {
        display: none;
    }
    .faq__item {
        height: 100px;

        &_active {
            height: 180px;
        }
    }
    .faq__item_content {
        max-height: 78px;
    }
    .faq__item_last:after {
        top: 99px;

    }
    .notification {
        height: 84px;
    }
    .notification__text {
        margin-left: 0;
        text-align: left;
        font-size: 14px;
    }
    .flip-card {
        height: 290px;
    }
}

@media (max-width: 680px) {
    html {
        font-size: 6px;
    }
    .row__button {
        padding: 40px 20px;
        justify-content: space-between;

        &_text {
            display: block;
            margin: 4rem 2rem;
        }
    }
    .triangles {
        grid-template-columns: 1fr;
        grid-gap: 2rem;

    }
    .triangle {
        &__right {
            transform: rotate(-90deg);
        }

        &__left {
            transform: rotate(90deg);

        }
    }
    .step__blocks {
        grid-template-columns: 1fr;
    }

    .text__content {
        font-size: 21px;
    }
    .step__block_title {
        font-size: 32px;
    }
    .button {
        &__yellow {
            &_mob {
                background: $yellow;
                color: $black;

                &:hover {
                    background: $black;
                    color: $yellow;
                }
            }
        }

        &__black {
            &-white {
                &_mob {
                    background: $black;
                    color: white;

                    &:hover {
                        color: $yellow;
                    }
                }
            }

            &_mob {
                background: $black;
                border: 1px solid $yellow;
                color: $yellow;

                &:hover {
                    border: 1px solid $black;
                    color: $white;
                }
            }
        }
    }

    .faq__item_title {
        min-height: 68px;
        display: flex;
        align-items: center;
    }
    .faq__info_container {
        padding: 5px;
    }
    .faq__item_img {
        display: none;

        &-mob {
            display: block;
        }
    }
    .faq__item {
        height: 110px;
        margin-top: 0;

        &_active {
            height: 280px;
        }
    }
    .faq__item_question {
        margin-bottom: 20px;
    }
    .faq__item_content {
        font-size: 15px;
        max-height: 150px;
    }
    .faq__item_container {
        padding: 24px 36px;
    }
    .faq__item_num {
        font-size: 48px;
        min-width: 65px;
    }
    .faq__item_last:after {
        top: 109px;
        width: 98%;

    }
    .step__block {
        padding: 50px 3rem;
    }
    .step__block_title {
        br {
            display: block;
        }
    }
    .message__wrapper {
        font-size: 140%;
    }
    .main {
        .socials__links {
            display: none;
        }
    }
    .faq__subtitle {
        margin-bottom: 8rem;
    }
}

@media (max-width: 660px) {
    .container__404 {
        display: flex;
        flex-direction: column;
        height: 100%;

        .lines {
            width: 100%;

            &__mob {
                display: block;
            }

            &__desk {
                display: none;
            }

            &__top {
                top: -1%;
            }

            &__bottom {
                bottom: -5%;
            }
        }

        .container__404__left {
            padding: 54px 20px 0;
        }
        .container__404__right {
            flex-grow: 1;
            padding: 68px 20px 60px;
            justify-content: center;
        }
        .page__content {
            max-width: 100%;
            width: 100%;
        }
        .page__text {
            text-align: center;

            &_wrapper {
                margin-bottom: 36px;
            }

            &_additional {
                font-size: 16px;
            }
        }
        .logo__404 {
            max-width: 200px;
        }
    }
}

@media (max-width: 600px) {
    html {
        font-size: 5.5px;
    }
    .advantages__wrapper {
        grid-template-columns: 1fr;
    }
    .advantage__number {
        font-size: 54px;
    }
    .advantage__text {
        font-size: 20px;
    }
    .moving-text__content {
        font-size: 54px;
    }
    .payments {
        border-top: none;
        padding: 8.4rem 0;

        &__desk {
            display: none;
        }

        &__mob {
            display: block;
        }
    }
    .button {
        max-width: 400px;
        width: 100%;
        height: 74px;
        font-size: 21px
    }
    .contact-us__dots-group {
        display: none;
    }
    .for-partners__button {
        margin: 0 auto;
    }
    .for-advertisers__button {
        margin: 0 auto 17rem;
    }
    .for-advertisers__lines {
        top: -20%;
        right: -20%;
    }
    .cooperation__lines-group {
        top: 82%;
    }
    .cooperation__squares_right, .cooperation__squares_left {
        display: none;
    }
    .cooperation__squares_mob {
        display: block;
        position: absolute;
        width: 263px;
        left: -5px;
        pointer-events: none;
        bottom: -130px;
    }
    .main {
        padding: 190px 0 36px;
        margin-top: 84px;
    }
    .faq__dots_right {
        display: none;
    }
    .faq__crosses {
        display: none;
    }
    .faq__crosses_bottom {
        display: block;
        left: 30%;
        transform: translateX(-50%);
        bottom: -90px;
    }
    .faq__section {
        padding: 124px 0 160px;
    }
    .for-partners__section {
        padding: 205px 0 160px;
    }
    .advantages__section {
        padding: 120px 0 150px;
    }
    .lines__group {
        font-size: 100%;
    }
    .cooperation__section {
        padding: 180px 0 240px;
    }
    .cooperation__lines-group_left {
        top: 40px;
    }
    .cooperation__lines-group_right {
        right: -10%;
        bottom: 20px;
    }
    .cooperation__arrows_right {
        top: -75px;
        right: 5px;
    }
    .cooperation__arrows_left {
        bottom: -200px;
        left: 5px;
    }

    .for-advertisers__section {
        padding: 204px 0 140px;
    }
    .swiper-pagination1 {
        bottom: -60px !important;
    }
    .contact-us__section {
        padding: 110px 0 160px;
    }
    .contact-us__title {
        padding-top: 30px;
    }
    .contact-us__arrows {
        right: 5px;
    }
    .testimonials__lines_top {
        top: 3%;
    }
    .testimonials__section {
        padding: 130px 0 160px;
    }
    .contact-us__subtitle {
        margin-bottom: 36px;

        br {
            display: block;
        }
    }
    .lines__right {
        bottom: 48%;
        right: -8%;
    }
    .faq__item_container {
        padding: 24px 30px;
    }
    .faq__item_last:after {
        width: 97%;
    }
    .flip-card {
        height: 232px;
    }
    .social__link {
        width: 32px;
        height: 32px;
    }
    .social__link_icon {
        width: 14px;
        height: 14px;
    }
    .social__link_icon_gmail {
        width: 12px;
        height: 12px;
    }
    .form__input {
        padding: 11px 16px;
    }
    .form__textarea {
        max-height: 48px;
    }
    .form__label {
        top: -2px;
        font-size: 15px;
    }
    .form__field {
        margin-bottom: 48px;
    }
    .form__error {
        font-size: 12px;
    }
    .radio__container {
        font-size: 15px;
    }
    .form__input-radio_wrapper {
        top: -6px;
    }
    .form__field_textarea {
        margin-bottom: 12px;
    }
    .form__field_checkbox, .form__field_captcha {
        margin-bottom: 21px;
    }
    .message__wrapper {
        bottom: 2%;
    }
    .form__wrapper {
        padding: 40px 10px;
    }
    .form__input::placeholder {
        font-size: 14px;
    }
    .testimonials__dots_top {
        right: 10%;
        top: -210px;
    }
    .testimonials__dots_bottom {
        left: 5%;
        top: auto;
        bottom: -150px;
    }
    .success {
        padding-top: 54px !important;
        padding-bottom: 76px !important;
    }
}

@media (max-width: 520px) {
    .descriptions {
        grid-template-columns: 1fr;
    }
    .message__button_hidden {
        opacity: 1;
        pointer-events: auto;
    }
    .title-section {
        font-size: 32px;
    }
    .text__content {
        font-size: 18px;
    }
    .main__title {
        font-size: 44px;
    }
    .header__wrapper {
        padding: 78px 30px;
    }
    .faq__item_title {
        font-size: 16px;
        min-height: 62px;
    }
    .faq__item_active {
        height: 300px;
    }
    .faq__item_content {
        max-height: 170px;
    }
    .flip-card {
        height: 230px;
    }
    .form__title {
        font-size: 18px;
    }
    .animation {
        font-size: 75%;
    }

    .lines__main, .lines__testimonials {
        font-size: 65%;
    }
    .notification__link:after {
        width: 100% !important;
    }
    .notification__link {
        display: block;
        width: max-content;
    }
    .success__title {
        margin-bottom: 20px;
    }
    .success__text {
        font-size: 14px;
        margin-bottom: 52px;
    }
    .container__404 {
        .page__title {
            margin-bottom: 32px;
        }
        .container__404__right {
            padding: 36px 20px 60px;
        }
        .logo__404 {
            max-width: 145px;
        }
    }
}

@media (max-width: 480px) {
    .faq__item_num {
        font-size: 42px;
        min-width: 57px;
    }
    .row__button {
        padding: 32px 16px;
    }
    .terms__rules {
        font-size: 15px;
    }
}

@media (max-width: 430px) {
    .faq__item_container {
        padding: 20px 30px;
    }
    .faq__item {
        height: 104px;
    }
    .faq__item_active {
        height: 296px;
    }
    .faq__item_title {
        font-size: 15px;
    }
    .faq__item_last:after {
        width: 97%;
        top: 103px;
    }
    .container {
        padding: 0 16px;
    }
    .triangle {
        width: 28px;
        height: 32px;
    }
    .advantage__number {
        font-size: 48px;
    }
    .advantage__text {
        font-size: 16px;
    }
    .about-us_text {
        font-size: 16px;
    }
    .about-us_num {
        font-size: 48px;
    }
    .title-section {
        font-size: 30px;
    }
    .container__404 {
        .container__404__left {
            padding: 44px 20px 0;
        }
        .container__404__right {
            padding: 44px 20px 60px;
        }
    }
}

@media (max-width: 410px) {
    .main__title {
        font-size: 38px;
    }

    .faq__item_title {
        min-height: 65px;
    }
    .faq__item_active {
        height: 280px;
    }
    .faq__item_content {
        max-height: 150px;
    }
    .faq__item_num {
        font-size: 40px;
        min-width: 54px;
    }
    .faq__item_last:after {
        top: 102px;
    }
}

@media (max-width: 390px) {
    .faq__item {
        height: 100px;
    }
    .faq__item_active {
        height: 280px;
    }
    .faq__item_content {
        max-height: 150px;
    }
    .faq__item_container {
        padding: 20px 24px 0;
    }
    .header__wrapper {
        padding: 48px 10px;
    }
    .faq__item_title {
        font-size: 14px;
    }
    .faq__item_last:after {
        top: 99px;
    }
}

@media (max-width: 370px) {
    .title-section {
        font-size: 28px;
    }
    .main__title {
        font-size: 36px;
    }
    .sing-in__link, .header .header__button {
        width: 100%;
        min-width: auto;
        max-width: 320px;
    }
}
