*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

a {
    text-decoration: none;
}

ul[class] {
    list-style: none;
}

html {
    font-size: 10px;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: $black;
}

.wrapper {
    overflow: hidden;
}


.container {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    position: relative;
}

.logo {
    max-width: 17rem;
    position: relative;
    z-index: 3;
    transition: all 0.3s ease-in-out;

    svg {
        width: 100%;
        height: 100%;

        path {
            transition: all 0.3s ease-in-out;
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 102%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 40;
    transition: all 0.5s ease-in-out;

    &__lines {
        position: absolute;
        top: 0;
        pointer-events: none;
        right: 2%;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        &_menu {
            display: none;
        }
    }

    &__container {
        padding: 3rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s ease-in-out;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .menu {
            margin-right: 7.5rem;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        width: max-content;
        height: 52px;
        padding: 15px 24px;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        margin: 0 32px;
    }

    &__scroll {
        background-color: $black;

        .notification {
            transform: translateY(-100%);
            height: 0;
            visibility: hidden;
        }

        .notification__close, .notification__text {
            opacity: 0;
        }
    }
}

.menu__mob {
    .notification {
        transform: translateY(-100%);
        height: 0;
        visibility: hidden;
    }

    .notification__close, .notification__text {
        opacity: 0;
    }
}

.link {
    white-space: nowrap;
}

.menu {
    &__list {
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-gap: 32px;
    }
}

.menu__btn {
    width: 28px;
    height: 24px;
    background: transparent;
    border: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px;
    justify-content: space-between;
    cursor: pointer;
    display: none;
    position: relative;

    &_line {
        display: block;
        width: 28px;
        height: 2px;
        background: $yellow;
        border-radius: 2px;
        transition: transform 0.3s ease-in-out;
        margin-bottom: 3px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &_close {
        .menu__btn_line {
            opacity: 0;

            &:first-child {
                opacity: 1;
                position: absolute;
                transform: rotate(45deg);
                top: 12px;


            }

            &:last-child {
                opacity: 1;
                position: absolute;
                transform: rotate(-45deg);
                top: 12px;

            }
        }
    }
}

.sing-in__link {
    text-transform: uppercase;
    color: $white;

    &:hover {
        color: $gray;
    }
}

.languages {
    display: flex;
    align-items: center;

    &__line {
        width: 1px;
        height: 14px;
        background: white;
        margin: 0 12px;
        display: flex;

    }
}

.main {
    padding: 33rem 0 9.2rem;
    position: relative;
    margin-top: 100px;
    transition: all 0.5s ease-in-out;

    &__top {
        margin-top: 0 !important;
    }

    &__title {
        margin: 0 auto 7.2rem;
        pointer-events: all;
    }

    &__button {
        margin: 0 auto 13rem;
        pointer-events: all;
    }

    &__container {
        pointer-events: none;
    }
}

.socials__links {
    position: absolute;
    right: -34px;
    top: 22%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: all;
}

.social {
    &__link {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-bottom: 10px;
        background: $darkGrey;
        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;

        &:hover {
            background: $yellow;
            border: 1px solid $darkGrey;

            .social__link_icon {
                fill: $black;

                &_fill {
                    fill: none;
                    stroke: $black;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &_icon {
            width: 20px;
            height: 20px;
            fill: white;
            transition: all 0.3s ease;

            &_fill {
                fill: none;
                stroke: white;
            }
        }
    }
}

.social__link_icon_gmail {
    width: 18px;
    height: 18px;
}

.descriptions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
}

.description {
    &__block {
        background: $dark;
        border: 1px solid $dark;
        border-radius: 10px;
        padding: 28px 30px 40px;
    }

    &__icon {
        width: 32px;
        height: 32px;
        display: block;
        margin: 0 auto 10px;
    }

    &__text {
        max-width: 220px;
        margin: 0 auto;
        font-weight: 600;
        font-size: 15px;
        line-height: 1.33;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.04em;
        color: #FFFFFF;

    }
}

.message__wrapper_animation {
    .message__button_border {
        animation: rotate 5s linear infinite;

    }
}

.message__button {
    width: 12em;
    height: 12em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 22;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &_border {
        position: absolute;
        width: 100%;
        height: 100%;
        //animation: rotate 5s linear infinite;
        border-radius: 50%;
        background: url("../img/message-button.svg") 50% 50%;
        background-size: 100% 100%;
    }

    &_hidden {
        opacity: 0;
        pointer-events: none;
    }

    &:hover {

        .message__button_border {
            animation: none;
        }
    }

    &_active {
        //box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.4);
        //background: $black;
        //border: 1px solid $yellow;

        .message__button_border {
            animation: none;
        }
    }

    &_icon {
        width: 5.2em;
        height: 5.2em;
        stroke: #877902;
        fill: none;
        transition: all 0.3s ease-in-out;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}


.message__wrapper {
    position: sticky;
    position: -webkit-sticky;
    bottom: 5%;
    margin: -15rem 4rem 2.4rem auto;
    z-index: 20;
    width: max-content;

    .socials__links {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scale(0);
        width: max-content;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        z-index: 21;
    }

    &_socials {
        .socials__links {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -100%) scale(1);
            top: -10%;
            width: max-content;
            opacity: 1;
        }
    }
}


.moving-text {
    background: $yellow;
    padding: 2.8rem 0;
    display: flex;
    overflow: hidden;

    &__content {
        width: max-content;
        white-space: nowrap;
        animation: move 10s linear infinite;
    }
}


@keyframes move {
    to {
        transform: translateX(-100%)
    }
}

.for-partners {
    &__section {
        padding: 20rem 0;
        position: relative;
        overflow: hidden;
        background: #090A0E;
    }

    &__dots {
        bottom: -35rem;
        left: 8%;
        transform: translateX(-50%);
        opacity: 0.05;
        fill: white;
    }

    &__crosses {
        &_left {
            transform: rotate(-90deg);
            left: -11%;
            top: -10%;
        }

        &_bottom {
            left: 40%;
            bottom: -12rem;
        }
    }

    &__title {
        margin-bottom: 3.2rem;
    }

    &__subtitle {
        margin-bottom: 7.2rem;
    }

}

.advantages {
    &__section {
        padding: 20rem 0;
    }

    &__dots {
        &_top {
            right: -5%;
            top: -5%;
        }

        &_left {
            left: -15%;
            top: 30%;
        }

        &_right {
            right: -15%;
            top: 55%;
        }
    }

    &__lines {
        position: absolute;
        left: -10%;
        bottom: -15%;
    }

    &__title {
        margin-bottom: 12.4rem;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

.advantage {
    &__block {
        background: $black;
        border-radius: 2rem;
        border: 1px solid $yellow;

        padding: 4.2rem 4rem;
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
            background: $yellow;

            .advantage__text, .advantage__number {
                color: $black;
            }
        }

    }

    &__number {
        font-weight: 800;
        font-size: 7.2rem;
        line-height: 1.2;
        transition: all 0.3s ease-in-out;
        margin-bottom: 3.2rem;
        text-align: center;
    }

    &__text {
        min-height: 8rem;
        height: max-content;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        font-size: 2.4rem;
        line-height: 1.4;
    }
}


.cooperation {
    &__section {
        padding: 26em 0;
        overflow: hidden;
    }

    &__arrows {
        &_right {
            top: -12rem;
            right: -10%;
        }

        &_left {
            bottom: -12rem;
            left: -10%;
        }
    }

    &__title {
        margin: 0 auto;
        font-size: 5.4rem;
        font-weight: 800;
    }

    &__squares {
        &_left {
            left: 0;
            bottom: -31rem;
        }

        &_right {
            right: 0;
            bottom: -31rem;
        }

        &_mob {
            display: none;
        }
    }

    &__lines-group {
        &_right {
            right: 0;
            bottom: 15%;

        }

        &_left {
            left: 0;
            top: 10%;
        }
    }

    &__dots {
        top: -68%;
        left: -2%;
        transform: translateX(-100%);
        opacity: 0.25;

        svg {
            fill: $black;
        }
    }
}

.for-advertisers {
    &__section {
        padding: 24rem 0 10rem;
        overflow: hidden;
    }

    &__title {
        margin-bottom: 3.2rem;
    }

    &__button {
        margin-bottom: 20rem;
    }

    &__lines {
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
        width: 82.2rem;
    }

    &__crosses {
        transform: translateX(-100%);
        left: -2%;
        top: -18%;
    }

    &__dots {
        bottom: -2%;
        left: 40%;
        transform: translateX(-100%);

        svg {
            fill: $white;
            opacity: 0.05;
        }

    }

    &__arrows {
        bottom: -10rem;
        left: 35%;
    }
}


.testimonials {
    &__section {
        padding: 22rem 0;
        background: url("../img/testimonials-bg.png") no-repeat no-repeat;
        background-size: cover;
    }

    &__dots {
        &_bottom {
            left: -8%;
            bottom: -14rem;
            z-index: 1;
        }

        &_top {
            right: -5%;
            top: -30%;
            left: auto;
            z-index: 1;
        }
    }


    &__swiper {
        padding: 7rem 0;
    }

    &__title {
        margin-bottom: 8rem;
    }

    &__slider {
        position: relative;
    }

    &__dots {
        right: -10%;
        top: -2%;

        svg {
            fill: $white;
            opacity: 0.1;
        }
    }

    &__lines {
        &_top {
            left: 0;
            top: 10%;
        }

        &_bottom {
            right: 0;
            bottom: 5%;
        }
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.testimonial {
    &__block {
        border-radius: 20px;
        padding: 4rem 4rem;
        transition: all 0.3s ease-in-out;
        opacity: 0.5;
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.22;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: $white;
        margin-bottom: 24px;

    }

    &__text {
        min-height: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
        letter-spacing: 0.01em;
        color: $white;

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__owner {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;

        &_img {
            margin-right: 22px;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            display: block;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &_text {

            font-size: 14px;
            line-height: 1.2;


        }

        &_name {
            font-weight: 600;
        }
    }

    &__stars {
        display: grid;
        grid-template-columns: repeat(5, 18px);
        grid-gap: 10px;
        width: max-content;
        margin: 0 auto 24px;
    }

    &__star {
        fill: $yellow;
        width: 18px;
        height: 18px;

        &_empty {
            fill: none;
            stroke: $yellow;
        }
    }
}

.testimonials-inner {
    margin-bottom: 8rem;
}

.testimonials__slider {
    .swiper-slide {
        position: relative;
        opacity: 0.3;
        transform: scale(.84);
        transition: all .3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #9b9797;
        border-radius: 20px;
        height: auto;
    }

    .swiper-slide-next {
        transform: scale(.84);
        transition: all .3s ease-in-out !important;
    }

    .swiper-backface-hidden .swiper-slide {
        transform: scale(.84) translateZ(0);
    }


    .swiper-slide-active, .swiper-slide-duplicate-active {
        border-radius: 20px;
        //height: 100%;
        position: relative;
        z-index: 34;
        transition: all 0.3s ease;
        //box-shadow: 0px 0px 3.5rem rgba(255, 245, 0, 0.5);
        transform: scale(1.1) !important;
        opacity: 1 !important;
        background: $yellow;
        border-color: $yellow;
        opacity: 1;
        box-shadow: 0px 0px 4rem rgba(255, 245, 0, 0.5);

        .testimonial__block {
            opacity: 1;
        }

        .testimonial__title, .testimonial__text, .testimonial__owner {
            color: $black;
        }

        .testimonial__star {
            fill: $black;

            &_empty {
                stroke: $black;
                fill: none;
            }
        }
    }
}


.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
}


.swiper-pagination {
    bottom: -1rem !important;
}

.swiper-pagination1 {
    bottom: -40px !important;
}

.swiper-pagination-bullet {
    width: 18px;
    height: 6px;
    border-radius: 4px;
    background: $gray;
    opacity: 1;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: $white;
    }
}

.swiper-pagination-bullet-active {
    background: $yellow;
    pointer-events: none;
}

.swiper-button-next, .swiper-button-prev {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        svg {
            stroke: $yellow;
            filter: drop-shadow(0 0 4rem $yellow);
        }
    }

    svg {
        width: 21px;
        height: 36px;
        stroke: white;
        fill: none;
        transition: all 0.3s ease-in-out;
    }
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: none;
}

.swiper-button-next {
    transform: rotate(180deg);
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 20%;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 20%;
}

.faq {
    &__section {
        padding: 18rem 0 8rem;
        position: relative;
        min-height: 120%;

    }

    &__crosses {
        z-index: 5;
        left: -10%;
        top: 32rem;
        transform: rotate(90deg);

        &_bottom {
            display: none;
        }
    }

    &__bg {
        width: 44.4em;
        height: 18rem;
        z-index: 2;
        position: absolute;
        display: flex;

        &_icon {
            width: 100%;
            height: 100%;
        }

        &_left {
            top: 76rem;
            left: -2%;

            .faq__bg_icon {
                fill: $dark;
            }
        }

        &_right {
            top: 175rem;
            right: -2%;

            .faq__bg_icon {
                fill: $white;
            }
        }
    }

    &__dots {
        &-group {
            left: -18%;
            top: 85rem;
        }

        &_top {
            top: 0;
            left: -8%;
        }

        &_left {
            top: 4rem;
            left: -5%;
        }

        &_right {
            right: 2%;
            top: 8rem;
            transform: rotate(90deg);
        }
    }

    &__mask {
        position: absolute;
        height: 110rem;
        background: $yellow;
        width: 100%;
        top: 0;
    }

    &__title {
        margin-bottom: 5.4rem;
    }

    &__subtitle {
        margin-bottom: 12rem;
    }

    &__info {
        position: relative;
        z-index: 3;
    }

    &__lines {
        position: absolute;
        top: 90rem;
        right: 0;
        user-select: none;
    }

    &__item {
        //background: $black;

        //border: 1px solid #FFE500;
        border-bottom: none;
        cursor: pointer;
        position: relative;
        height: 160px;
        transition: all 0.3s ease-in-out;
        //border-radius: 2rem 2rem 0 0;
        overflow: hidden;
        margin-top: -5px;

        //&:last-child {
        //  border-radius: 2rem;
        //  border-bottom: 1px solid #FFE500;
        //}

        &_last {
            position: relative;

            &.faq__item_active {
                &:after {
                    opacity: 0;
                }
            }

            &:after {
                content: '';
                position: absolute;
                width: 96%;
                height: 1px;
                background: $yellow;
                left: 50%;
                transform: translateX(-50%);
                top: 159px;
                z-index: 1;

            }
        }

        &_img {
            &-mob {
                display: none;
            }
        }

        &_bg {
            position: absolute;
            width: 100%;
            height: 100%;

            &_active {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                top: 0;
                //img {
                //  min-height: 100%;
                //}
                //z-index: 2;
            }
        }

        &_container {
            padding: 32px 72px 40px;
            position: relative;
            z-index: 2;
        }

        //&:hover {
        //
        //  .faq__item_bg_active {
        //    opacity: 1;
        //  }
        //
        //  .faq__item_title, .faq__item_num {
        //    color: $black;
        //  }
        //
        //  .faq__item_content {
        //    color: $yellow;
        //  }
        //}

        &_active {
            //background: $yellow;
            //border-color: $black;
            height: 350px;
            //box-shadow: 0px 0px 5px #FFE500;

            .faq__item_bg_active {
                opacity: 1;
            }

            &:hover {
                .faq__item_content {
                    color: $black !important;
                }
            }

            .faq__item_title, .faq__item_num {
                color: $black;
            }

            .faq__item_content {
                height: 100%;
                opacity: 1;
                visibility: visible;
            }
        }

        &_question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 2;
            margin-bottom: 32px;
        }

        &_title {
            font-weight: 700;
            font-size: 24px;
            line-height: 1.4;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $yellow;
            transition: all 0.3s ease;
            margin-right: 26px;
        }

        &_num {
            font-weight: 800;
            font-size: 7.2rem;
            line-height: 1.18;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            transition: all 0.3s ease;
            min-width: 10.5rem;
            text-align: center;
        }

        &_content {
            font-weight: 400;
            font-size: 21px;
            line-height: 1.4;

            letter-spacing: 0.01em;

            color: $black;
            height: 0;
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            max-height: 170px;
            overflow-y: auto;
            padding-right: 14px;
            @include customScrollbar(6px, $black, rgba($black, 0.1));
        }
    }
}


.about-us {
    &__wrapper {
        position: relative;
        display: none;
    }

    &__scroll {
        display: block;
    }

    &__swiper {
        overflow: visible;
    }

    &_dots {
        &_bottom {
            left: 50%;
            bottom: 1rem;
            transform: translate(-50%, 0) rotate(90deg);
        }

        &_group {
            position: absolute;
            right: 20%;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 0.5rem;

            .dots-double {
                position: relative;

                &:nth-child(2n) {
                    transform: rotate(90deg) translateY(2.8rem);
                }

                &:last-child {
                    transform: rotate(90deg) translateY(5.6rem);
                }
            }
        }
    }

    &__container {
        display: flex;
        //overflow-x: scroll;
        position: relative;
        z-index: 2;
        transition: all 0.3s ease-in;
    }


    &_block {
        padding: 6rem 5.5rem;
        min-height: 45vh;
        position: relative;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &_item {
        border-left: 1px solid $yellow;
        min-width: 33%;


        &:hover {


            .about-us_num {
                color: $yellow !important;
            }

        }

    }

    &_num {
        font-weight: 800;
        font-size: 7.2rem;
        line-height: 1.18;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        margin-bottom: 4.6rem;
        -webkit-text-stroke: 1px $yellow;
        color: transparent !important;
        transition: all 0.3s ease-in-out;
    }

    &_text {
        font-weight: 400;
        font-size: 2.4rem;
        line-height: 1.4;
        display: flex;
        align-items: center;
        text-align: left;
        height: max-content;
        letter-spacing: 0.01em;
        min-height: 15rem;
        transition: all 0.3s ease-in-out;
    }
}

.contact-us {
    &__section {
        padding: 18rem 0 29rem;
        overflow: hidden;
    }

    &__lines {
        &-group {
            left: -2%;
            bottom: 2rem;
        }
    }

    &__line {
        &_top {
            transform: rotate(-105deg) translateY(-50%);
            left: 15%;
            top: -100rem;
            position: absolute;
            pointer-events: none;
        }

        &_bottom {
            transform: rotate(-140deg);
            right: -10%;
            top: 55%;
            position: absolute;
            pointer-events: none;
        }
    }

    &__dots {
        fill: $white;
        opacity: 0.25;
        right: -16%;
        top: 2rem;

        &-group {
            transform: rotate(-90deg);
            top: 44rem;
            left: -14%;
        }
    }

    &__arrows {
        right: -10%;
        bottom: -12rem;
    }

    &__text {
        &_img {
            position: absolute;
            right: -7%;
            top: 14rem;
        }
    }

    &__title {
        margin-bottom: 3.2rem;
        padding-top: 4rem;
    }

    &__subtitle {
        margin-bottom: 8.2rem;

        br {
            display: none;
        }
    }

    &__steps {
        display: flex;
        justify-content: center;
        margin-bottom: 8.2rem;

        &_mob {
            display: none;
        }
    }

    &__step {
        background: transparent;
        border: none;
        outline: none;
        font-size: 21px;
        //cursor: pointer;
        //
        //&:hover {
        //  color: $yellow;
        //}

        &_line {
            margin: 0 1.5rem;
        }

        &_active {
            color: $yellow;
            pointer-events: none;
        }

        &_click {
            pointer-events: all;
            cursor: pointer;
        }

    }
}

.footer {
    position: relative;
    z-index: 20;

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.01em;
        padding: 2rem 0;
    }
}

.step {
    &__hidden {
        display: none;
    }

    &__blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;
    }

    &__block {
        padding: 10rem 4rem;
        border: 1px solid #FFE500;
        border-radius: 20px;
        background: $black;

        &_yellow {
            background: #FFE500;
        }

        &_title {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 5.4rem;

            br {
                display: none;
            }
        }
    }
}

.payments {
    padding: 3.2rem 0;
    border-bottom: 1px solid #3A3C3E;

    &__logos {
        margin: 0 auto;
    }

    &__desk {
        display: block;
    }

    &__mob {
        display: none;
    }
}

.faq__block {
    position: absolute;
    width: 100%;
}


.form {
    max-width: 540px;
    margin: 0 auto;
    width: 100%;
    display: none;

    &__wrapper {
        max-width: 820px;
        background: $dark;
        margin: 0 auto;
        padding: 8.2rem;
    }

    &__title {
        text-transform: uppercase;
        margin-bottom: 9rem;
        font-size: 24px;
    }

    &__field {
        position: relative;
        margin-bottom: 60px;

        &_hidden {
            display: none;
        }

        &:hover {
            .form__input {
                border-color: $yellow;
                color: $yellow;
            }

            .form__label {
                color: $yellow;
            }
        }

        &_textarea {
            margin-bottom: 21px;
        }

        &_checkbox {
            margin-bottom: 21px;
        }

        &_captcha {
            margin-bottom: 21px;
        }
    }

    &__input {
        width: 100%;
        padding: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.01em;
        color: $black;
        outline: none;
        background: #3A3C3E;
        border-radius: 10px;
        border: 1px solid #3A3C3E;
        transition: all 0.3s ease-in-out;

        &:active, &:focus {
            border-color: transparent !important;
            color: $white !important;

            & + .form__label {
                color: $white !important;
            }
        }

        &[required] + .form__label:after {
            content: '*';
        }

        &::placeholder {
            color: $black;
        }

        &-radio {
            &_wrapper {
                top: -12px;
                transform: translateY(-100%);
                position: absolute;
                display: flex;
                align-items: center;
            }
        }
    }

    &__error {
        color: rgba(255, 0, 0, 1);
        font-size: 13px;
        position: absolute;
        left: 0;
        top: 101%;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;

        &_captcha {
            position: relative;
        }
    }

    &__label {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.01em;
        color: $gray;
        position: absolute;
        left: 0;
        top: -12px;
        transform: translateY(-100%);
        transition: all 0.3s ease-in-out;

        &.required:after {
            content: '*';
        }
    }

    &__textarea {
        max-height: 56px;
        resize: none;
        padding: 15px 16px;
    }
}

.error {
    .form__input {
        color: rgba(255, 0, 0, 1) !important;
        border-color: rgba(255, 0, 0, 1) !important;
    }

    .form__label {
        color: rgba(255, 0, 0, 1) !important;
    }

    .form__error {
        opacity: 1;
        pointer-events: all;
    }
}


.form__content_advertiser {
    .form__advertiser {
        display: block;
    }
}

.form__content_affiliate {
    .form__affiliate {
        display: block;
    }
}

.radio__container {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $white;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 32px;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: $yellow;
    }

    &:last-child {
        margin-right: 0;
    }
}

/* Hide the browser's default radio button */
.radio__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio__checkmark {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    left: 0;
    height: 16px;
    width: 16px;
    background: #3A3C3E;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio__container:hover input ~ .radio__checkmark {
    background-color: rgba(58, 60, 62, 0.8);
}

/* When the radio button is checked, add a blue background */
.radio__container input:checked ~ .radio__checkmark {
    background-color: #3A3C3E;
}

.radio__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio__container input:checked ~ .radio__checkmark:after {
    display: block;

}

/* Style the indicator (dot/circle) */
.radio__container .radio__checkmark:after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.checkbox__container {
    display: block;
    position: relative;
    padding-left: 48px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.01em;
    color: $darkGrey;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    a {
        color: $darkGrey;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

/* Hide the browser's default checkbox */
.checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 16px;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1.5px solid $darkGrey;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkbox__container:hover input ~ .checkmark {
}

/* When the checkbox is checked, add a blue background */


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox__container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox__container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


@media(hover: hover) and (pointer: fine) {
    .faq__item {
        &:hover {
            .faq__item_bg_active {
                opacity: 1;
            }

            .faq__item_title, .faq__item_num {
                color: $black;
            }

            .faq__item_content {
                color: $yellow;
            }
        }
    }

    .notification__link {
        &:hover {
            &:after {
                width: 0;
            }
        }
    }
}

.notification {
    background: $yellow;
    position: relative;
    transition: all 0.5s ease-in-out;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__hidden {
        height: 0 !important;

        .notification__close, .notification__text {
            opacity: 0;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        letter-spacing: 0.01em;
        transition: all 0.3s ease-in-out;
        margin: 0 auto;
    }

    &__link {
        font-weight: 500;
        position: relative;
        color: black;
        white-space: nowrap;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            top: 102%;
            background: $black;
            position: absolute;
            left: 0;
            transition: all 0.5s ease-in-out;
        }


    }

    &__close {
        position: absolute;
        right: 20px;
        width: 32px;
        height: 32px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;

        &_icon {
            width: 22px;
            height: 22px;
        }

        &:hover {
            transform: scale(1.1) translateY(-50%);
        }
    }
}

.success {
    padding-top: 10.2rem !important;
    padding-bottom: 10.2rem !important;

    &__title {
        margin-bottom: 32px;
    }

    &__text {
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.16px;
        margin-bottom: 7.2rem;
    }
}

.terms {
    padding: 20rem 0 14rem;
    position: relative;

    &__title {
        margin-bottom: 9rem;
    }

    &__rules {
        p {
            color: $lightGray;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.16px;
        }

        a {
            color: $lightGray;
            text-decoration: underline;

            &:hover {
                color: white;
                text-decoration: none;
            }
        }
    }
}


.about-us__card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 45vw;
}

.horizontal-sections {
    position: relative;
    overflow-x: hidden;

    .pin-wrap,
    .animation-wrap {
        display: flex;
        position: relative;
        z-index: 1;
        height: 100vh;

    }
}

.overflow {
    overflow: hidden;
}

.container__404 {
    display: grid;
    grid-template-columns: 55% 45%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    &__left {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px;
    }

    .lines {
        position: absolute;
        width: 100%;
        height: auto;
        pointer-events: none;

        &__top {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &__bottom {
            bottom: 0;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
        }

        &__desk {
            display: block;
        }

        &__mob {
            display: none;
        }
    }

    .logo__404 {
        display: block;
        width: 350px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .page {
        &__content {
            max-width: 540px;
            margin: 0 auto;

            &_text {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        &__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 1.25;
            text-align: center;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #FFE500;
            margin: 0 auto 54px;
        }

        &__text {
            font-weight: 500;
            font-size: 21px;
            line-height: 1.43;
            letter-spacing: 0.01em;

            &_wrapper {
                margin-bottom: 62px;
            }

            &_main {
                color: #F8F8F8;
            }

            &_additional {
                color: #666666;
            }

        }
    }
}
